@import "../../../SCSS/variables";

.filters {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    margin: 2rem 1rem;

    h1 {
        font-size: 2.2rem;
        font-weight: 600;
        margin-bottom: 0rem;

    }

    .searchbox_short {
        display: flex;
        align-items: center;
        border: 1px solid #ccc;
        border-radius: 8px;
        padding: 0 12px;
        height: 40px;
        width: 500px;

        input {
            border: none;
            outline: none;
            width: 200px;
            font-size: 14px;
            color: #555;
            background: transparent;

            &::placeholder {
                color: #aaa;
            }
        }

        i {
            margin-left: 8px;
            color: #aaa;
            font-size: 16px;
        }
    }

    .counts {
        display: flex;
        align-items: center;
        background-color: #0b8043;
        color: #fff;
        border-radius: 20px;
        padding: 0 12px;
        font-size: 14px;
        font-weight: 500;
        height: 40px;

        span {
            background: #fff;
            color: #000;
            font-size: 12px;
            font-weight: bold;
            border-radius: 50px;
            padding: 6px 10px;
            margin-left: 8px;
        }
    }
}

.wrp_video {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 3rem;
    max-width: 100%;
    overflow-x: hidden;

    .video_wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        cursor: pointer;
        max-width: 100%;
      
        overflow-x: hidden;


        .data_icons_container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            .date_cont {
                display: flex;
                justify-content: space-between; 
                align-items: center;
                width: 100%;
                gap: 3px;
                border-left: none;
                overflow: hidden;
            
            
                .leftContent {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    overflow: hidden;
                    max-width: 70%;
                }
                
                .videoTitle, .videoPlayer {
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    color:black;
                }
                
               
                .videoDate {
                    font-size: 13px;  
                    opacity: 0.8;
                    white-space: nowrap;
                    text-align: right; 
                }
                
            
            .playerName {
                font-size: 10px;  
                color: green;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 100%;
                text-align: left; 
            }
            
            

                p:first-child {
                    font-size: 10px;
                    margin: 0;
                    opacity: 0.9;
                    border-left: 5px solid $cta_color; 
                    padding-inline: 5px;
                }
                
                p {
                    font-size: 14px;
                    margin: 0;
                    opacity: 0.9;
                    padding-inline: 5px;
                }
            }

            .icons_cont {
                display: flex;
                align-items: center;

                i {
                    color: green;
                    cursor: pointer;
                    margin-inline: 4px;
                }
            }
        }


        .vertical-line {
            width: 2px;
            height: 150px;
            border-left: 1px solid black;
        }

        .video_box {
            position: relative;

            .video_cont {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                border-radius: 10px;
                height: 250px;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;

                button {
                    background-color: $cta_color;
                    color: white;
                    width: 100%;
                    margin-top: -40px;
                    box-sizing: border-box;
                    position: absolute;
                    bottom: 0;
                    border-radius: 0;
                    z-index: 2;
                    text-transform: capitalize;
                    font-size: 20px;
                    font-weight: 500;
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                }

                img {
                    width: 100%;
                    border-radius: 10px;
                }

            }

            .icons {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                cursor: pointer;
                color: white;
                font-size: 40px;
                z-index: 2;
                transform: translate(-50%, -50%);
                display: flex;
                column-gap: 10px;
                justify-content: space-evenly;

                .play_icon,
                .download_icon {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    span {
                        font-size: 16px;
                    }
                }

                .play_icon {
                    margin-right: 30px;
                }
            }

        }

    }
}

.video_section {
    position: relative;
    margin: 2rem 1rem;

    h1 {
        font-size: 2.2rem;
        font-weight: 600;
        margin-bottom: 2rem;
    }

    .error {
        color: red;
    }

    .add_video_icon {
        position: absolute;
        bottom: 6rem;
        right: 1rem;
        z-index: 10;
        padding: 0.7rem 1rem;
        background-color: $cta_color;
        border-radius: 50%;
        border: 2px solid $cta_color;
        cursor: pointer;
        transition: background-color 0.4s ease,
            border-color 0.4s ease;

        i {
            color: $white;
            font-size: 2rem;
            transition: color 0.4s ease;
        }

        &:hover {
            background-color: $white;

            i {
                color: $cta_color;
            }
        }
    }
}

.no_video_view_cont {
    background-color: $background_color;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-block: 5rem;


    h1 {
        font-weight: 500;
        font-size: 48px;
        line-height: 78px;
    }

    p {
        font-weight: 500;
        font-size: 24px;
    }

    a {
        font-weight: 700;
        color: $cta_color;
    }

}

.videoContainer {
    background-color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@keyframes scale-up {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.5);
    }

    100% {
        transform: scale(1);
    }
}

.favIcon {
    transition: transform 0.3s ease-in-out;
}

.animate {
    animation: scale-up 0.3s;
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.video_wrapper {
    transition: opacity 0.8s ease-in-out;

    &.deleting {
        animation: fadeOut 0.8s forwards;
    }
}


@media screen and (max-width: 1024px) {
    
    .filters {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
        margin:  1rem;
    
        h1 {
            font-size: 24px;
            font-weight: 600;
            margin-bottom: 0rem;
    
        }
    
    }
    .video_section {
        position: relative;
        margin:1rem;
    
        h1 {
            font-size: 2.2rem;
            font-weight: 600;
            margin-bottom: 1rem;
        }
    
        .error {
            color: red;
        }
    
        .add_video_icon {
            position: absolute;
            bottom: 6rem;
            right: 1rem;
            z-index: 10;
            padding: 0.7rem 1rem;
            background-color: $cta_color;
            border-radius: 50%;
            border: 2px solid $cta_color;
            cursor: pointer;
            transition: background-color 0.4s ease,
                border-color 0.4s ease;
    
            i {
                color: $white;
                font-size: 2rem;
                transition: color 0.4s ease;
            }
    
            &:hover {
                background-color: $white;
    
                i {
                    color: $cta_color;
                }
            }
        }
    }
        .wrp_video {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            justify-items: "space-between";
            gap: 2rem;
        
            .video_wrapper {
                display: flex;
                flex-direction: column;
                width: 100%;
        
                .data_icons_container {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
        
                    .date_cont {
                        display: flex;
                        align-items: center;
                        margin: 1rem 0;
        
                        // span {
                        //     height: 100%;
                        //     width: 5px;
                        //     background-color: $cta_color;
                        //     // margin-right: 5px;
                        // }
        
                        p {
                            font-size: 14px;
                            margin: 0;
                            opacity: 0.9;
                            border-left: 5px solid $cta_color;
                            padding-inline: 5px;
                        }
                    }
        
                    .icons_cont {
                        display: flex;
                        align-items: center;
        
                        i {
                            color: green;
                            cursor: pointer;
                            margin-inline: 4px;
                        }
                    }
                }
        
        
                .vertical-line {
                    width: 2px;
                    height: 150px;
                    border-left: 1px solid black;
                }
        
                .video_box {
                    position: relative;
        
                    .video_cont {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        border-radius: 10px;
                        height: 250px;
                        background-size: cover;
                        background-position: center;
                        background-repeat: no-repeat;
        
                        button {
                            background-color: $cta_color;
                            color: white;
                            width: 100%;
                            margin-top: -40px;
                            box-sizing: border-box;
                            position: absolute;
                            bottom: 0;
                            border-radius: 0;
                            z-index: 2;
                            text-transform: capitalize;
                            font-size: 14px;
                            font-weight: 500;
                            border-bottom-left-radius: 10px;
                            border-bottom-right-radius: 10px;
                        }
        
                        img {
                            width: 100%;
                            border-radius: 10px;
                        }
        
                    }
        
                    .icons {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        cursor: pointer;
                        color: white;
                        font-size: 40px;
                        z-index: 2;
                        transform: translate(-50%, -50%);
                        display: flex;
                        column-gap: 10px;
                        justify-content: space-evenly;
        
                        .play_icon,
                        .download_icon {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
        
                            span {
                                font-size: 16px;
                            }
                        }
        
                        .play_icon {
                            margin-right: 30px;
                        }
                    }
        
                }
        
            }
        }
    }

@media screen and (max-width: 768px) {
    
    .filters {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
        margin:  1rem;
    
        h1 {
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 0rem;
    
        }
    
    }
    .video_section {
        position: relative;
        margin:1rem;
    
        h1 {
            font-size: 2.2rem;
            font-weight: 600;
            margin-bottom: 1rem;
        }
    
        .error {
            color: red;
        }
    
        .add_video_icon {
            position: absolute;
            bottom: 6rem;
            right: 1rem;
            z-index: 10;
            padding: 0.7rem 1rem;
            background-color: $cta_color;
            border-radius: 50%;
            border: 2px solid $cta_color;
            cursor: pointer;
            transition: background-color 0.4s ease,
                border-color 0.4s ease;
    
            i {
                color: $white;
                font-size: 2rem;
                transition: color 0.4s ease;
            }
    
            &:hover {
                background-color: $white;
    
                i {
                    color: $cta_color;
                }
            }
        }
    }
        .wrp_video {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            justify-items: "space-between";
            gap: 1.5rem;
        
            .video_wrapper {
                display: flex;
                flex-direction: column;
                width: 100%;
        
                .data_icons_container {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
        
                    .date_cont {
                        display: flex;
                        align-items: center;               
                        margin: 1rem 0;
        
                        // span {
                        //     height: 100%;
                        //     width: 5px;
                        //     background-color: $cta_color;
                        //     // margin-right: 5px;
                        // }
        
                        p {
                            font-size: 14px;
                            margin: 0;
                            opacity: 0.9;
                            border-left: 5px solid $cta_color;
                            padding-inline: 5px;
                        }
                    }
        
                    .icons_cont {
                        display: flex;
                        align-items: center;
        
                        i {
                            color: green;
                            cursor: pointer;
                            margin-inline: 4px;
                        }
                    }
                }
        
        
                .vertical-line {
                    width: 2px;
                    height: 150px;
                    border-left: 1px solid black;
                }
        
                .video_box {
                    position: relative;
        
                    .video_cont {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        border-radius: 10px;
                        height: 250px;
                        background-size: cover;
                        background-position: center;
                        background-repeat: no-repeat;
        
                        button {
                            background-color: $cta_color;
                            color: white;
                            width: 100%;
                            margin-top: -40px;
                            box-sizing: border-box;
                            position: absolute;
                            bottom: 0;
                            border-radius: 0;
                            z-index: 2;
                            text-transform: capitalize;
                            font-size: 14px;
                            font-weight: 500;
                            border-bottom-left-radius: 10px;
                            border-bottom-right-radius: 10px;
                        }
        
                        img {
                            width: 100%;
                            border-radius: 10px;
                        }
        
                    }
        
                    .icons {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        cursor: pointer;
                        color: white;
                        font-size: 40px;
                        z-index: 2;
                        transform: translate(-50%, -50%);
                        display: flex;
                        column-gap: 10px;
                        justify-content: space-evenly;
        
                        .play_icon,
                        .download_icon {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
        
                            span {
                                font-size: 16px;
                            }
                        }
        
                        .play_icon {
                            margin-right: 30px;
                        }
                    }
        
                }
        
            }
        }
    }

@media screen and (max-width:468px) {
    
.filters {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    margin:  1rem;

    h1 {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 0rem;

    }

}
.video_section {
    position: relative;
    margin:1rem;

    h1 {
        font-size: 2.2rem;
        font-weight: 600;
        margin-bottom: 1rem;
    }

    .error {
        color: red;
    }

    .add_video_icon {
        position: absolute;
        bottom: 6rem;
        right: 1rem;
        z-index: 10;
        padding: 0.7rem 1rem;
        background-color: $cta_color;
        border-radius: 50%;
        border: 2px solid $cta_color;
        cursor: pointer;
        transition: background-color 0.4s ease,
            border-color 0.4s ease;

        i {
            color: $white;
            font-size: 2rem;
            transition: color 0.4s ease;
        }

        &:hover {
            background-color: $white;

            i {
                color: $cta_color;
            }
        }
    }
}
    .wrp_video {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        justify-items: "space-between";
        gap: 3rem;
    
        .video_wrapper {
            display: flex;
            flex-direction: column;
            width: 100%;
    
            .data_icons_container {
                display: flex;
                justify-content: space-between;
                align-items: center;
    
                .date_cont {
                    display: flex;
                    align-items: center;
                    margin: 1rem 0;
    
                    // span {
                    //     height: 100%;
                    //     width: 5px;
                    //     background-color: $cta_color;
                    //     // margin-right: 5px;
                    // }
    
                    p {
                        font-size: 14px;
                        margin: 0;
                        opacity: 0.9;
                        border-left: 5px solid $cta_color;
                        padding-inline: 5px;
                    }
                }
    
                .icons_cont {
                    display: flex;
                    align-items: center;
    
                    i {
                        color: green;
                        cursor: pointer;
                        margin-inline: 4px;
                    }
                }
            }
    
    
            .vertical-line {
                width: 2px;
                height: 150px;
                border-left: 1px solid black;
            }
    
            .video_box {
                position: relative;
    
                .video_cont {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    border-radius: 10px;
                    height: 250px;
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
    
                    button {
                        background-color: $cta_color;
                        color: white;
                        width: 100%;
                        margin-top: -40px;
                        box-sizing: border-box;
                        position: absolute;
                        bottom: 0;
                        border-radius: 0;
                        z-index: 2;
                        text-transform: capitalize;
                        font-size: 14px;
                        font-weight: 500;
                        border-bottom-left-radius: 10px;
                        border-bottom-right-radius: 10px;
                    }
    
                    img {
                        width: 100%;
                        border-radius: 10px;
                    }
    
                }
    
                .icons {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    cursor: pointer;
                    color: white;
                    font-size: 40px;
                    z-index: 2;
                    transform: translate(-50%, -50%);
                    display: flex;
                    column-gap: 10px;
                    justify-content: space-evenly;
    
                    .play_icon,
                    .download_icon {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
    
                        span {
                            font-size: 16px;
                        }
                    }
    
                    .play_icon {
                        margin-right: 30px;
                    }
                }
    
            }
    
        }
    }
}

.noVideosFoundForTag {
    color: red;
    font-size: 18px;
    text-align: center;
    align-items: center;
    margin-top: 3%;
  }