@import "../../SCSS/variables";

.container_upload_section {
    position: relative;
    padding: 2rem 3rem;
    overflow: auto;

    .drag_over {
        border-color: $cta_color;
        background-color: rgba(0, 197, 79, 0.1);
    }

    .header {

        display: flex;
        justify-content: space-between;
        align-items: center;

        h2 {
            font-size: 1.6rem;
            font-weight: 700;
            margin-bottom: 1.8rem;
        }

        button {
            background-color: transparent;
            outline: none;
            border: 2px solid $inactive_text_color;
            padding: 0px;
            margin: 0px;
            padding: 4px 10px;
            border-radius: 50%;
            font-size: 14px;
            font-weight: 600;
            position: absolute;
            top: 1rem;
            right: 1rem;
        }
    }

    .left_container {

        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .button_container {

            button {
                width: 100%;
                color: white;
                font-size: 16px;
                font-weight: 500;
                background-color: $cta_color;
                padding: 0.5rem 1rem;
                text-transform: uppercase;
                border: none;
            }
        }

        .browse_container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100%;

            svg {
                color: $cta_color;
                width: 55px;
                height: 70px;
            }

            .custom_icon {
                color: $cta_color;
                font-size: 42px;
            }

            h2 {
                margin-top: 1rem;
                color: $cta_color;
                font-weight: $h2_font_weight;
                font-size: 1.8rem;
            }

            .browseFile {
                font-size: 1rem;
                color: black;
                font-weight: 700;
                margin-top: 2rem;
                text-align: center; 
                width: 100%;

                label {
                    color: $cta_color;
                    cursor: pointer;
                    text-decoration: underline;
                }

                // iPhone specific fixes
                @supports (-webkit-touch-callout: none) {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 4px;
                }
            }

            .fileSupportedType {
                font-size: 12px;
                opacity: 0.5;
                color: 000000;
                font-weight: 500;
                margin: 0px;
            }

            .success_message {
                font-size: 12px;
                color: $cta_color;
                font-weight: 500;
                text-align: center;
                margin-bottom: 1rem;

            }

            .error_message {
                font-size: 12px;
                color: red;
                text-align: center;
                margin-bottom: 1rem;
            }
        }

        .filename {
            font-size: 16px;
            font-weight: 600;
            text-align: center;
            display: inline-block; // Ensure it respects width properly
        }
        

        .progress_container {
            padding: 0 2rem 2rem;

            .button_group {
                text-align: right;
                display: flex;
                align-items: center;
                justify-content: space-between;

                >p {
                    font-weight: 600;
                    size: 20px;
                    color: #000000;
                }

                button {
                    padding: 0;
                    display: inline-block;
                    background-color: transparent;
                    padding: 0 0.5rem;
                    color: #000;
                    border: none;
                    opacity: 0.8;
                }

            }

            .progress_bar {
                background-color: rgba(0, 128, 0, 0.311);
                border-radius: 10px;

                span {
                    background-color: $cta_color;
                    border-radius: 10px;

                }
            }

            .progress_info {
                display: flex;
                align-items: center;
                justify-content: space-between;

                p {
                    margin: 0.5rem 0;
                    font-size: 12px;
                    font-weight: 500;
                }
            }
        }

    }

    

}

// Remove the filename input container styles as they're no longer needed
// .filename_input_container { ... }

.bottom_wrap {
    padding: 10px;

    p {
        text-decoration: underline;
        font-size: 14px;
        cursor: pointer;
        color: $cta_color;
        font-weight: 600;
    }
}

.record_container {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    video {
        width: 100%;
        max-width: 400px;
        margin-bottom: 15px;
        border-radius: 8px;
    }
}

.record_button {
    padding: 10px 20px;
    border-radius: 20px;
    border: none;
    background-color: #247E45;
    color: white;
    font-weight: bold;
    cursor: pointer;
    margin: 10px 0;
    
    &.recording {
        background-color: #ff4444;
        animation: pulse 1.5s infinite;
    }
}

@keyframes pulse {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.7;
    }
    100% {
        opacity: 1;
    }
}

.recorded_video_container {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    background: #f5f5f5;
    border-radius: 8px;
    overflow: hidden;

    video {
        width: 100%;
        height: auto;
        display: block;
        background: #f5f5f5;
    }
}

.recording_container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.recording_progress {
  width: 100%;
  height: 4px;
  background-color: #ddd;
  border-radius: 2px;
  overflow: hidden;

  .progress_bar {
    height: 100%;
    background-color: #ff4444;
    transition: width 1s linear;
  }
}
