@import '../../SCSS/variables';

.nav {
  position: fixed;
  width: 100%;
  z-index: 11;
  top: 0px;
  background-color: $primary_color;
  height: 70px;

  .nav_wrp {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    padding-inline: 1rem;

    .alertContainer {
      position: absolute;
      left: 40%;
      top: 10%;
    }

    .logo {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      img {
        align-self: center;
        width: 200px;
      }
    }

    .menuContainer {
      display: flex;
      align-items: center;

      .menu {
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 1.5rem 2rem;

        ul {
          list-style: none;
          padding: 0;
          margin: 0;

          li {
            display: inline;
            padding: 0 1rem;

            a {
              color: $white;
              font-size: 20px;
              font-weight: 500;
              text-transform: capitalize;
              text-decoration: none;

            }

            .active {
              font-weight: 800;
            }

          }
        }

        div {
          display: flex;
          align-items: center;
          flex-direction: row;
          cursor: pointer;
          padding-left: 1rem;

          button {
            margin: 0;
            padding: 0;
            border: none;
            outline: none;
            background-color: inherit;

            svg {
              width: 30px;
            }

          }

          span {
            color: $white;
            font-size: 20px;
            overflow: hidden;
            margin-left: 0.5rem;
          }
        }
      }

      .bar-icon {
        display: none;
      }
    }




  }

  .logout_menu {
    a {
      span {

        text-decoration: none !important;
      }
    }
  }
}

.mobile_nav {
  display: none;
}

.toggle_menu {
  display: none;
}


@media screen and (max-width: 1399px) {
  .nav {
    position: fixed;
    width: 100%;
    z-index: 3;
    top: 0px;
    background-color: $primary_color;
    height: 70px;

    .nav_wrp {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      position: relative;
      padding-inline: 1rem;

      .alertContainer {
        position: absolute;
        left: 40%;
        top: 10%;
      }

      .logo {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        img {
          align-self: center;
          width: 180px;
        }
      }

      .menuContainer {
        display: flex;
        align-items: center;

        .menu {
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: space-around;
          padding: 1.5rem 2rem;

          ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
              display: inline;
              padding: 0 1rem;

              a {
                color: $white;
                font-size: 20px;
                font-weight: 500;
                text-transform: capitalize;
                text-decoration: none;

              }

              .active {
                font-weight: 800;
              }

            }
          }

          div {
            display: flex;
            align-items: center;
            flex-direction: row;
            cursor: pointer;
            padding-left: 1rem;

            button {
              margin: 0;
              padding: 0;
              border: none;
              outline: none;
              background-color: inherit;

              svg {
                width: 30px;
              }

            }

            span {
              color: $white;
              font-size: 20px;
              overflow: hidden;
              margin-left: 0.5rem;
            }
          }
        }

        .bar-icon {
          display: none;
        }
      }




    }

    .logout_menu {
      a {
        span {

          text-decoration: none !important;
        }
      }
    }
  }

  .mobile_nav {
    display: none;
  }

  .toggle_menu {
    display: none;
  }
}


@media screen and (max-width: 1199px) {}


@media screen and (max-width: 992px) {
  .nav {
    position: fixed;
    width: 100%;
    z-index: 3;
    top: 0px;
    background-color: $primary_color;
    height: 70px;

    .nav_wrp {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      position: relative;
      padding-inline: 1rem;

      .alertContainer {
        position: absolute;
        left: 40%;
        top: 10%;
      }

      .logo {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        img {
          align-self: center;
          width: 175px;
        }
      }

      .menuContainer {
        display: flex;
        align-items: center;

        .menu {
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: space-around;
          padding: 1.5rem 2rem;

          ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
              display: inline;
              padding: 0 1rem;

              a {
                color: $white;
                font-size: 17px;
                font-weight: 500;
                text-transform: capitalize;
                text-decoration: none;

              }

              .active {
                font-weight: 800;
              }

            }
          }

          div {
            display: flex;
            align-items: center;
            flex-direction: row;
            cursor: pointer;
            padding-left: 1rem;

            button {
              margin: 0;
              padding: 0;
              border: none;
              outline: none;
              background-color: inherit;

              svg {
                width: 30px;
              }

            }

            span {
              color: $white;
              font-size: 17px;
              overflow: hidden;
              margin-left: 0.5rem;
            }
          }
        }

        .bar-icon {
          display: none;
        }
      }




    }

    .logout_menu {
      a {
        span {

          text-decoration: none !important;
        }
      }
    }
  }

  .mobile_nav {
    display: none;
  }

  .toggle_menu {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .nav {
    display: none;
  }

  #basic-menu {
    display: none;
  }

  .mobile_nav {
    display: block;
    position: fixed;
    width: 100%;
    top: 0px;
    z-index: 6;
    background-color: $primary_color;

    .top_nav {
      background-color: $white;
      padding: 0.2rem 0;
      font-family: 'Poppins', sans-serif;
      text-align: right;

      .pro_cont {
        display: flex;
        align-items: baseline;
        justify-content: flex-end;
        flex-direction: row;
        cursor: pointer;
        padding-left: 1rem;

        button {
          margin: 0;
          padding: 0;
          border: none;
          outline: none;
          background-color: inherit;

          svg {
            width: 20px;
          }

        }


        span {
          color: #000;
          font-size: 12px;
          font-weight: 600;
          overflow: hidden;
          margin-left: 0.5rem;
        }
      }
    }

    .nav_bar {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 0.2rem 0;

      .logo {
        width: 30%;

        img {
          width: 100%;
        }
      }

      .toggle_button {
        button {
          position: relative;
          width: 50px;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          border: none;
          background-color: transparent;
          display: block;
          z-index: 4;

          &.menuicons span {
            transform: translateX(60px);
          }

          &.menuicons span:nth-child(1) {
            transform: translateY(0px);
            width: 20px;
            transform: rotate(50deg);
          }

          &.menuicons span:nth-child(2) {
            transform: translateY(0px);
            width: 20px;
            transform: rotate(128deg);
          }

          & span {
            position: absolute;
            width: 30px;
            height: 2.5px;
            background-color: $white;
            border-radius: 4px;
            transition: 0.5s;
            right: 15px;

            &:nth-child(1) {
              transform: translateY(-8px);
              width: 20px;
              left: 15px;
            }

            &:nth-child(2) {
              transform: translateY(8px);
              width: 20px;
              left: 15px;
            }
          }
        }

        .menu-one {
          display: none;
        }
      }
    }
  }

  .toggle_menu {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    content: "";
    z-index: 5;
    box-shadow: 1px 10px 11px 3px #00000017;
    font-family: "Kanit", sans-serif;
    background-color: #fff;
    margin: 0;
    transform: translate(110%);
    padding-top: 8rem;
    overflow: hidden;
    overflow-y: scroll;
    height: auto;

    .menus {
      .navbar_nav {
        padding: 0;

        li {
          width: 100%;
          display: block;
          text-align: left;
          margin: 0rem 2rem;
          padding: 0.5rem;
          border-bottom: 1px dotted rgb(199, 198, 198);

          button {
            border: none;
            background-color: transparent;
            color: #000;
            font-weight: 600;
            font-size: 0.8em;
          }

          .dropdown-toggle {
            position: relative;

            &::after {
              display: inline-block;
              margin-left: 0;
              vertical-align: 0.255em;
              content: "";
              border-top: 0.3em solid;
              border-right: 0.3em solid transparent;
              border-bottom: 0;
              border-left: 0.3em solid transparent;
              position: absolute;
              top: 50%;
              right: 0;
            }
          }

          a {
            width: 100%;
            font-size: 0.8em;
            font-weight: 600;
            letter-spacing: 0.50px;
            // font-family: "Kanit", sans-serif;  
            line-height: 2rem;
            text-decoration: none;
            color: #000;
            text-transform: capitalize;

            &.active {
              font-weight: 800;
              font-size: 0.86em;
            }
          }

          .menuItemText {
            font-size: 0.8em;
            font-weight: 600;
            letter-spacing: 0.50px;
            // font-family: "Kanit", sans-serif;  
            line-height: 2rem;
            text-decoration: none;
            color: #000;
            text-transform: capitalize;

            svg {
              width: 20px;
              height: 20px;
            }

            span {
              font-size: 0.8rem;
              font-weight: 600;
              letter-spacing: 0.50px;
              // font-family: "Kanit", sans-serif;  
              line-height: 2rem;
              text-decoration: none;
              color: #000;
              text-transform: capitalize;
            }
          }

          ul {
            background-color: transparent;
            border: none;

            li {
              padding: 0;
              background-color: #000;
              margin: 0rem;
              padding: 0 1rem;
            }
          }
        }
      }
    }

    .bottom {
      padding-top: 3rem;
    }

    .add {
      position: relative;
      top: 0;
      bottom: 0;
      background-color: transparet;
      height: auto;
      color: #000;
      padding: 2rem 2rem 0;
      text-align: center;

      i {
        font-size: 0.9rem;

        padding-bottom: 1rem;
      }

      h1 {
        font-size: 1rem;
        font-weight: 600;
        padding-bottom: 0.5rem;
        font-family: "Kanit", sans-serif;
      }

      h6 {
        padding-bottom: 2rem;
        border-bottom: 1px solid #f3f0f040;
        font-size: 0.8rem;
        opacity: 0.5;
        font-family: "Kanit", sans-serif;
      }

      p {
        font-size: 0.8rem;
        opacity: 0.5;
        padding-bottom: 1rem;
      }

      ul {
        li {
          border: none;
          display: inline;
          padding: 0;
          margin: 0 0.5rem;
          background-color: transparent;

          a {
            color: #000;
            font-size: 1.2rem;
            opacity: 0.5;
          }
        }
      }
    }
  }

  .actives {
    transform: translate(50%);
    transition: 0.8s;
  }
}

@media screen and (max-width: 576px) {
  .nav {
    display: none;
  }

  #basic-menu {
    display: none;
  }

  .mobile_nav {
    display: block;
    position: fixed;
    width: 100%;
    top: 0px;
    z-index: 6;
    background-color: $primary_color;

    .top_nav {
      background-color: $white;
      padding: 0.2rem 0;
      font-family: 'Poppins', sans-serif;
      text-align: right;

      .pro_cont {
        display: flex;
        align-items: baseline;
        justify-content: flex-end;
        flex-direction: row;
        cursor: pointer;
        padding-left: 1rem;

        button {
          margin: 0;
          padding: 0;
          border: none;
          outline: none;
          background-color: inherit;

          svg {
            width: 20px;
          }

        }


        span {
          color: #000;
          font-size: 12px;
          font-weight: 600;
          overflow: hidden;
          margin-left: 0.5rem;
        }
      }
    }

    .nav_bar {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 0.1rem 0;

      .logo {
        width: 40%;

        img {
          width: 100%;
        }
      }

      .toggle_button {
        button {
          position: relative;
          width: 50px;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          border: none;
          background-color: transparent;
          display: block;
          z-index: 4;

          &.menuicons span {
            transform: translateX(60px);
          }

          &.menuicons span:nth-child(1) {
            transform: translateY(0px);
            width: 20px;
            transform: rotate(50deg);
          }

          &.menuicons span:nth-child(2) {
            transform: translateY(0px);
            width: 20px;
            transform: rotate(128deg);
          }

          & span {
            position: absolute;
            width: 30px;
            height: 2.5px;
            background-color: $white;
            border-radius: 4px;
            transition: 0.5s;
            right: 15px;

            &:nth-child(1) {
              transform: translateY(-8px);
              width: 20px;
              left: 15px;
            }

            &:nth-child(2) {
              transform: translateY(8px);
              width: 20px;
              left: 15px;
            }
          }
        }

        .menu-one {
          display: none;
        }
      }
    }
  }

  .toggle_menu {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    content: "";
    z-index: 5;
    box-shadow: 1px 10px 11px 3px #00000017;
    font-family: "Kanit", sans-serif;
    background-color: #fff;
    margin: 0;
    transform: translate(110%);
    padding-top: 8rem;
    overflow: hidden;
    overflow-y: scroll;
    height: auto;

    .menus {
      .navbar_nav {
        padding: 0;

        li {
          width: 100%;
          display: block;
          text-align: left;
          margin: 0rem 2rem;
          padding: 0.5rem;
          border-bottom: 1px dotted rgb(199, 198, 198);

          button {
            border: none;
            background-color: transparent;
            color: #000;
            font-weight: 600;
            font-size: 0.8em;
          }

          .dropdown-toggle {
            position: relative;

            &::after {
              display: inline-block;
              margin-left: 0;
              vertical-align: 0.255em;
              content: "";
              border-top: 0.3em solid;
              border-right: 0.3em solid transparent;
              border-bottom: 0;
              border-left: 0.3em solid transparent;
              position: absolute;
              top: 50%;
              right: 0;
            }
          }

          a {
            width: 100%;
            font-size: 0.8em;
            font-weight: 600;
            letter-spacing: 0.50px;
            // font-family: "Kanit", sans-serif;  
            line-height: 2rem;
            text-decoration: none;
            color: #000;
            text-transform: capitalize;

            &.active {
              font-weight: 800;
              font-size: 0.86em;
            }
          }

          .menuItemText {
            font-size: 0.8em;
            font-weight: 600;
            letter-spacing: 0.50px;
            // font-family: "Kanit", sans-serif;  
            line-height: 2rem;
            text-decoration: none;
            color: #000;
            text-transform: capitalize;

            svg {
              width: 20px;
              height: 20px;
            }

            span {
              font-size: 0.8rem;
              font-weight: 600;
              letter-spacing: 0.50px;
              // font-family: "Kanit", sans-serif;  
              line-height: 2rem;
              text-decoration: none;
              color: #000;
              text-transform: capitalize;
            }
          }

          ul {
            background-color: transparent;
            border: none;

            li {
              padding: 0;
              background-color: #000;
              margin: 0rem;
              padding: 0 1rem;
            }
          }
        }
      }
    }

    .bottom {
      padding-top: 3rem;
    }

    .add {
      position: relative;
      top: 0;
      bottom: 0;
      background-color: transparet;
      height: auto;
      color: #000;
      padding: 2rem 2rem 0;
      text-align: center;

      i {
        font-size: 0.9rem;

        padding-bottom: 1rem;
      }

      h1 {
        font-size: 1rem;
        font-weight: 600;
        padding-bottom: 0.5rem;
        font-family: "Kanit", sans-serif;
      }

      h6 {
        padding-bottom: 2rem;
        border-bottom: 1px solid #f3f0f040;
        font-size: 0.8rem;
        opacity: 0.5;
        font-family: "Kanit", sans-serif;
      }

      p {
        font-size: 0.8rem;
        opacity: 0.5;
        padding-bottom: 1rem;
      }

      ul {
        li {
          border: none;
          display: inline;
          padding: 0;
          margin: 0 0.5rem;
          background-color: transparent;

          a {
            color: #000;
            font-size: 1.2rem;
            opacity: 0.5;
          }
        }
      }
    }
  }

  .actives {
    transform: translate(50%);
    transition: 0.8s;
  }
}

