.video-tag {
    background-color: #247E45; /* Green background */
    color: white; /* White text */
    padding: 6px 12px; /* Padding for a tag-like look */
    border-radius: 16px; /* Rounded corners */
    font-size: 100%; /* Adjust font size */
    font-weight: 500; /* Slightly bold */
    margin-right: 8px; /* Space between tags */
    display: inline-block; /* Ensures proper spacing */

    .cancelButton {

      margin-left: 7px;
      margin-right: 1px;
      background-color: transparent; /* Green background */
      color: white;
      border: none;


    }
  }