.video-tags-select-container {
  display: flex;
  gap: .5%; /* Space between tags */
  background-color : #D9F0DE;
  border-radius: 8px;
  padding: 2%;
  margin-bottom: 3%;
  width: 100%;
  min-width: 190px;
  

.tagListDropdown {
  position: relative;
  display: inline-block;
  height: 50%;
  padding: 2%;
  

  select {
      width: 100%;
      height:70%;
      padding: 10px;
      width: min-content;
      min-height: 40px;
      border: 2px solid #247E45; // Green border
      border-radius: 8px;
      background-color: white;
      font-size: 16px;
      cursor: pointer;
      transition: border-color 0.1s ease-in-out, box-shadow 0.1s ease-in-out;

      &:hover {
          background-color : #D9F0DE;
      }

      &:focus {
          outline: none;
          box-shadow: 0 0 5px #000000;
      }

      option {
          background-color: #D9F0DE; // Default background
          color: black;
          padding: 10px;
          font-weight: bold;


          
      }

  }
}



}


.video-tags {
  display: flex; /* Makes this a flex container */
  flex-wrap: wrap; /* Allows inner elements to wrap */
  gap: 3px;
  padding: 2px;
  
}



@media (max-width: 600px) {
  .video-tags-select-container {
    flex-direction: column; /* Stack first and second divs */
  }

  .video-tags {
    flex-wrap: wrap; /* Allow items inside to wrap */
  }
}
