@import "../../SCSS/variables";

footer {
    background-color: $secondary_color;
    padding: 5rem 0 1rem;

    .wrp {
        flex: 1;
        border-top: 1px solid rgba($color: #000000, $alpha: 0.8);
        padding: 2rem 0;
        display: flex;
        // grid-template-columns: repeat(4,1fr);
        align-items: stretch;
        justify-content: space-between;
        column-gap: 2rem;

        // align-items: center;
        .box {
            max-width: 250px;
            color: #000;

            h6 {
                font-size: 18px;
                font-weight: 700;
                text-transform: uppercase;

            }

            ul {
                padding: 0;
                margin: 0;

                li {
                    display: block;
                    margin-bottom: 0.8rem;

                    a {
                        font-size: 18px;
                        font-weight: 500;
                        color: #000;
                        text-decoration: none;

                        i {
                            margin-right: 5px;
                        }

                        &:hover {
                            text-decoration: underline;
                        }
                    }


                }
            }

            >button {
                background: transparent;
                color: #000;
                border: none;
                padding: none;
                text-transform: uppercase;
                text-decoration: none;
                font-size: 1.125rem;
                font-weight: 400;
                padding: 5px 10px;

                &:hover {
                    background-color: $cta_color;
                    color: $background_color;
                }

                >span {
                    margin-left: 1rem;
                }
            }

            .socialIcons {
                display: flex !important;
                gap: 1rem;
                
                a {
                    font-size: 20px;
                    
                    &:hover {
                        text-decoration: none;
                        opacity: 0.8;
                    }
                }
            }
        }

        // .box_social{
        //     width: 25%;
        //     h6{
        //         font-size: 18px;
        //         font-weight: 700;
        //         text-transform: capitalize;

        //     }
        //     ul{
        //         padding-top: 0.5rem;
        //         li{
        //             display: inline;
        //             padding-right: 1rem;
        //             img{
        //                 width:10%;
        //             }
        //         }
        //     }
        // }
    }

    .footer_btm {
        display: flex;
        align-items: center;
        justify-content: center;

        #border {
            flex: 1;
            border-top: 1px solid rgba($color: #000000, $alpha: 0.8);
        }

        .company_name {
            padding: 5px 15px;
            /* Adjust padding as needed */
            font-size: 14px;
            margin: 0;
            text-transform: uppercase;
        }
    }


}

@media screen and (max-width: 1399px) {

    footer {
        background-color: $secondary_color;
        padding: 5rem 0 1rem;

        .wrp {
            flex: 1;
            border-top: 1px solid rgba($color: #000000, $alpha: 0.8);
            padding: 2rem 0;
            display: flex;
            // grid-template-columns: repeat(4,1fr);
            align-items: stretch;
            justify-content: space-between;
            column-gap: 2rem;

            // align-items: center;
            .box {
                max-width: 250px;
                color: #000;

                h6 {
                    font-size: 17px;
                    font-weight: 700;
                    text-transform: uppercase;

                }

                ul {
                    padding: 0;
                    margin: 0;

                    li {
                        display: block;
                        margin-bottom: 0.8rem;

                        a {
                            font-size: 16px;
                            font-weight: 500;
                            color: #000;
                            text-decoration: none;

                            i {
                                margin-right: 5px;
                            }

                            &:hover {
                                text-decoration: underline;
                            }
                        }


                    }
                }

                >button {
                    background: transparent;
                    color: #000;
                    border: none;
                    padding: none;
                    text-transform: uppercase;
                    text-decoration: none;
                    font-size: 1.1rem;
                    font-weight: 400;


                    &:hover {
                        background-color: $cta_color;
                        color: $background_color;
                    }

                    >span {
                        margin-left: 1rem;
                    }
                }

                .socialIcons {
                    display: flex !important;
                    gap: 1rem;
                    
                    a {
                        font-size: 20px;
                        
                        &:hover {
                            text-decoration: none;
                            opacity: 0.8;
                        }
                    }
                }
            }

            // .box_social{
            //     width: 25%;
            //     h6{
            //         font-size: 18px;
            //         font-weight: 700;
            //         text-transform: capitalize;

            //     }
            //     ul{
            //         padding-top: 0.5rem;
            //         li{
            //             display: inline;
            //             padding-right: 1rem;
            //             img{
            //                 width:10%;
            //             }
            //         }
            //     }
            // }
        }

        .footer_btm {
            display: flex;
            align-items: center;
            justify-content: center;

            #border {
                flex: 1;
                border-top: 1px solid rgba($color: #000000, $alpha: 0.8);
            }

            .company_name {
                padding: 5px 15px;
                /* Adjust padding as needed */
                font-size: 14px;
                margin: 0;
                text-transform: uppercase;
            }
        }


    }
}


@media screen and (max-width: 1199px) {
    footer {
        padding: 3rem 0 1rem;

        .wrp {
            column-gap: 1rem;

            .box {
                max-width: 250px;

                h6 {
                    font-size: 17px;
                }

                ul {
                    li {
                        margin-bottom: 0.5rem;

                        a {
                            font-size: 15px;

                            i {
                                margin-right: 5px;
                            }
                        }


                    }
                }

                >button {
                    font-size: 16px;

                    >span {
                        margin-left: 0.5rem;
                    }
                }

                .socialIcons {
                    display: flex !important;
                    gap: 1rem;
                    
                    a {
                        font-size: 20px;
                        
                        &:hover {
                            text-decoration: none;
                            opacity: 0.8;
                        }
                    }
                }
            }
        }

    }
}


@media screen and (max-width: 992px) {
    footer {
        padding: 2rem 0 1rem;

        .wrp {
            column-gap: 1rem;

            .box {
                max-width: 250px;

                h6 {
                    font-size: 16px;
                }

                ul {
                    li {
                        margin-bottom: 0.5rem;

                        a {
                            font-size: 14px;

                            i {
                                margin-right: 5px;
                            }
                        }


                    }
                }

                >button {
                    font-size: 15px;

                    >span {
                        margin-left: 0.5rem;
                    }
                }

                .socialIcons {
                    display: flex !important;
                    gap: 1rem;
                    
                    a {
                        font-size: 20px;
                        
                        &:hover {
                            text-decoration: none;
                            opacity: 0.8;
                        }
                    }
                }
            }
        }

    }
}

@media screen and (max-width: 768px) {

    footer {
        padding: 1.5rem 0 1rem;

        .wrp {
            column-gap: 1rem;

            .box {
                max-width: 250px;

                h6 {
                    font-size: 14px;
                }

                ul {
                    li {
                        margin-bottom: 0.5rem;

                        a {
                            font-size: 13px;

                            i {
                                margin-right: 5px;
                            }
                        }


                    }
                }

                >button {
                    font-size: 14px;

                    >span {
                        margin-left: 0.5rem;
                    }
                }

                .socialIcons {
                    display: flex !important;
                    gap: 1rem;
                    
                    a {
                        font-size: 20px;
                        
                        &:hover {
                            text-decoration: none;
                            opacity: 0.8;
                        }
                    }
                }
            }
        }

    }
}

@media screen and (max-width: 576px) {
    footer {
        padding: 2rem 0 1rem;

        .wrp {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: 1rem;
            padding: 1rem 1rem;

            .box {
                max-width: 100%;

                h6 {
                    font-size: 13px;
                }

                ul {

                    li {
                        margin-bottom: 0.4rem;

                        a {
                            font-size: 12px;

                            i {
                                margin-right: 5px;
                            }
                        }


                    }
                }

                >button {
                    font-size: 12px;
                    font-weight: 700;

                    >span {
                        margin-left: 0rem;
                    }
                }

                .socialIcons {
                    display: flex !important;
                    gap: 1rem;
                    
                    a {
                        font-size: 20px;
                        
                        &:hover {
                            text-decoration: none;
                            opacity: 0.8;
                        }
                    }
                }
            }
        }

        .footer_btm {
            .company_name {
                padding: 4px;
                max-width: 50%;
                font-size: 10px;
                text-align: center;
            }
        }


    }
}