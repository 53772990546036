.search-container{
        display: "flex";
        gap: "5%";


        .searchBox{
            border-radius: 8px;
            border: 1px solid #329633;
            padding : 1%;
            width : 78%;
            margin-bottom: 2%;
            transition: border-width 0.1s ease-in-out, border-color 0.1s ease-in-out; 

            &:focus {
                border-width: 2px; // Increase border thickness on focus
                border-color: #329633; // Optional: Darker shade on focus

                outline: none; // Removes default browser outline
              }

              
        
        }

        

        .searchButton{
            border-radius: 8px;
            border: none; // Removes the border
            padding : 1%;
            width : 22%;
            background-color: #247E45; /* Green background */
            color: white; /* White text */
            font-family: "Montserrat", sans-serif;
            font-size: 16px;
            font-weight: bolder;
            transition: background-color 0.4s ease-in-out, color 0.4s ease-in-out, border 0.4s ease-in-out ;

            &:hover {
                background-color: #FFFFFF; /* Darker green on hover */
                color: #247E45;
                border: 1px solid #329633;
            }
        
        }

}




// {
//         padding: "10px",
//         border: "1px solid #ccc",
//         borderRadius: "5px",
//         fontSize: "16px",
//         flex: "1",
// }

// {
//         padding: "10px 15px",
//         backgroundColor: "#007bff",
//         color: "white",
//         border: "none",
//         borderRadius: "5px",
//         cursor: "pointer",
// }

