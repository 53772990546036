* {
  font-family: "Montserrat", sans-serif;
  margin: 0;
  padding: 0;

}

:root {
  scroll-behavior: smooth;
}



body {
  padding-top: 70px;
  font-family: "Montserrat", sans-serif;
}
body.modal-open {
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
li,
button {
  font-family: "Montserrat", sans-serif;
}

// ul {
//   margin: 0;
//   padding: 0;
// }

ol {
  list-style-type: decimal !important;
}


.slick-dots {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  z-index: 99999;
}

.slick-dots li {
  margin: 0 5px;
}

.slick-dots li button {
  width: 20px;
  height: 20px;
  border: none;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.452);
  cursor: pointer;
}

.slick-dots li.slick-active button {
  background-color: #fff;
}

.slick-dots li.slick-active button:before {
  color: rgb(2, 65, 2);
  font-size: 12px;
}

.slick-dots li button:before {
  font-size: 12px;
}
// .video_controls{
//   position: relative;
//   width: 100%;
  .video-js{
    width: 100% !important;
    height: 65vh;
  
  }
// }
.video-js .vjs-play-progress:before{
  top: unset !important;
}

.video-js .vjs-control{
  z-index: 2;
}
// .video-js .vjs-text-track-display{
//   position: relative;

// }
// .konva_stage{
//   position: absolute
// }

.video-js .vjs-control {
  width: 3em
}

.video-js .vjs-menu-button-inline:before {
  width: 1.5em;
  z-index: 9999;
}

.vjs-menu-button-inline .vjs-menu {
  left: 3em;
  z-index: 9999;
}




.video-js .vjs-mouse-display:after,.video-js .vjs-play-progress:after {
  padding: 0 .4em .3em
}

.video-js .vjs-control-bar {
  opacity: 1 !important;
  background-color: rgba(0,0,0,0.54) !important;
  color: #ffffff;
  font-size: 14px
}

.video-js .vjs-play-progress,.video-js  .vjs-volume-level {
  background-color: #ffffff
}

.video-js .vjs-slider {
  background-color: #3b3d41
}

.video-js .vjs-load-progress {
  background: rgba(255,255,255,0.4);
}

.video-js .vjs-big-play-button {
  border-color: transparent;
  top: 50% !important;
  left: 50% !important;
}

.video-js *{
  outline: none !important;
}
