@import "../../../SCSS/variables";
.filters {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    margin: 2rem 1rem;

    h1 {
        font-size: 2.2rem;
        font-weight: 600;
        margin-bottom: 0rem;

    }
    .counts {
        display: flex;
        align-items: center;
        background-color: #0b8043;
        color: #fff;
        border-radius: 20px;
        padding: 0 12px;
        font-size: 14px;
        font-weight: 500;
        height: 40px;

        span {
            background: #fff;
            color: #000;
            font-size: 12px;
            font-weight: bold;
            border-radius: 50px;
            padding: 6px 10px;
            margin-left: 8px;
        }
    }
}

.videos_section {
    position: relative;
    min-height: 100dvh;
    overflow-y: auto;

    .wrp {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        justify-items: center;
        gap: 2rem;
    }

    .video_wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        cursor: pointer;

        .data_icons_container {
            display: flex;
            
            justify-content: space-between;
            align-items: center;

            .date_cont {
                display: flex;
                
                flex-direction: column;
                align-items: flex-start;
                margin: 1rem 0;
                position: relative;

                p {
                    font-size: 10px; // Smaller font size for date
                    margin: 0;
                    color: gray;
                    opacity: 0.7;
                    padding-inline: 5px;
                    border-left: 5px solid $cta_color;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 180px;

                    
                }

                .videoTitle {
                    font-size: 14px; // Normal font size for title
                    font-weight: 500;
                    color: black; 
                    max-width: 180px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            .icons_cont {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                width: 100%;
                margin-top: 5px;

                i {
                    color: green;
                    cursor: pointer;
                    margin-inline: 4px;
                }
            }
        }

        .vertical-line {
            width: 2px;
            height: 150px;
            border-left: 1px solid black;
        }

        .video_box {
            position: relative;

            .video_cont {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                border-radius: 10px;
                height: 250px;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;

                button {
                    background-color: $cta_color;
                    color: white;
                    width: 100%;
                    margin-top: -40px;
                    box-sizing: border-box;
                    position: absolute;
                    bottom: 0;
                    border-radius: 0;
                    z-index: 2;
                    text-transform: capitalize;
                    font-size: 20px;
                    font-weight: 500;
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                }

                img {
                    width: 100%;
                    // border-radius: 10px;
                }

            }

            .icons {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                cursor: pointer;
                color: white;
                font-size: 40px;
                z-index: 2;
                transform: translate(-50%, -50%);
                display: flex;
                column-gap: 10px;
                justify-content: space-evenly;

                .play_icon,
                .download_icon {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    span {
                        font-size: 16px;
                    }
                }

                .play_icon {
                    margin-right: 30px;
                }
            }

        }

    }

    .error {
        color: red;
    }

    .add_video_icon {
        position: fixed;
        bottom: 40px;
        right: 3rem;
        z-index: 10;
        padding: 0.7rem 1rem;
        background-color: $cta_color;
        border-radius: 50%;
        border: 2px solid $cta_color;
        cursor: pointer;
        transition: background-color 0.4s ease,
            border-color 0.4s ease;

        i {
            color: $white;
            font-size: 2rem;
            transition: color 0.4s ease;
        }

        &:hover {
            background-color: $white;

            i {
                color: $cta_color;
            }
        }
    }
}

.no_video_view_cont {
    background-color: $background_color;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-block: 5rem;


    h1 {
        font-weight: 500;
        font-size: 48px;
        line-height: 78px;
    }

    p {
        font-weight: 500;
        font-size: 24px;
    }

    a {
        font-weight: 700;
        color: $cta_color;
    }

}

.videoContainer {
    background-color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@keyframes scale-up {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.5);
    }

    100% {
        transform: scale(1);
    }
}

.favIcon {
    transition: transform 0.3s ease-in-out;
}

.animate {
    animation: scale-up 0.3s;
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.video_wrapper {
    transition: opacity 0.8s ease-in-out;

    &.deleting {
        animation: fadeOut 0.8s forwards;
    }
}

@media screen and (max-width: 1399px) {
    .videos_section {
        padding-top: 0rem;
        background-color: $background_color;

        .wrp {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            justify-items: "space-between";
            gap: 3rem;

        }



        .video_wrapper {
            display: flex;
            flex-direction: column;
            width: 100%;


            .data_icons_container {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .date_cont {
                    display: flex;
                    align-items: flex-start;
                    margin: 1rem 0;

                    p {
                        font-size: 12px;
                        margin: 0;
                        opacity: 0.9;
                    }
                }

                .icons_cont {
                    display: flex;
                    align-items: center;

                    i {
                        color: green;
                        cursor: pointer;
                    }
                }
            }


            .vertical-line {
                width: 2px;
                height: 150px;
                border-left: 1px solid black;
            }

            .video_box {
                position: relative;

                .video_cont {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    // border-radius: 10px;

                    button {
                        background-color: $cta_color;
                        color: white;
                        width: 100%;
                        margin-top: -40px;
                        box-sizing: border-box;
                        position: absolute;
                        bottom: 0;
                        border-radius: 0;
                        z-index: 2;
                        text-transform: capitalize;
                        font-size: 20px;
                        font-weight: 500;
                        border-bottom-left-radius: 10px;
                        border-bottom-right-radius: 10px;
                    }

                    img {
                        width: 100%;
                        // border-radius: 10px;
                    }

                }

                .icons {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    cursor: pointer;
                    color: white;
                    font-size: 40px;
                    z-index: 2;
                    transform: translate(-50%, -50%);
                    display: flex;
                    column-gap: 10px;
                    justify-content: space-evenly;

                    .play_icon,
                    .download_icon {
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        span {
                            font-size: 16px;
                        }
                    }

                    .play_icon {
                        margin-right: 30px;
                    }
                }

            }

        }

        .error {
            color: red;
        }

    }

    .no_video_view_cont {
        background-color: $background_color;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-block: 4rem;


        h1 {
            font-weight: 500;
            font-size: 40px;
            line-height: 78px;
        }

        p {
            font-weight: 500;
            font-size: 18px;
        }

        a {
            font-weight: 700;
            color: $cta_color;
        }

    }

    .videoContainer {
        background-color: black;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}


@media screen and (max-width: 1199px) {

    .videos_section {
        padding-top: 0rem;

        .wrp {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            justify-items: "space-between";
            gap: 3rem;

        }


        .video_wrapper {
            display: flex;
            flex-direction: column;
            width: 100%;

            .date_cont {
                display: flex;
                align-items: center;
                align-items: center;
                margin: 1rem 0;


                p {
                    font-size: 12px;
                    margin: 0;
                    opacity: 0.9;
                }
            }

            .vertical-line {
                width: 2px;
                height: 150px;
                border-left: 1px solid black;
            }

            .video_box {
                position: relative;

                .video_cont {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    // border-radius: 10px;

                    button {
                        margin-top: -40px;
                        bottom: 0;
                        font-size: 24px;
                    }

                }

                .icons {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    cursor: pointer;
                    color: white;
                    font-size: 40px;
                    z-index: 2;
                    transform: translate(-50%, -50%);
                    display: flex;
                    column-gap: 10px;
                    justify-content: space-evenly;

                    .play_icon,
                    .download_icon {
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        span {
                            font-size: 16px;
                        }
                    }

                    .play_icon {
                        margin-right: 30px;
                    }
                }

            }

        }

        .error {
            color: red;
        }

    }

    .no_video_view_cont {
        h1 {
            font-size: 40px;
        }

        p {
            font-size: 20px;
        }

    }

    .videoContainer {
        background-color: black;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}


@media screen and (max-width: 992px) {

    .videos_section {
        padding-top: 0rem;

        .wrp {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            justify-items: "space-between";
            gap: 2.5rem;

        }

        .video_wrapper {
            display: flex;
            flex-direction: column;
            width: 100%;

            .date_cont {
                display: flex;
                align-items: center;
                align-items: center;
                margin: 1rem 0;


                p {
                    font-size: 12px;
                    margin: 0;
                    opacity: 0.9;
                }
            }

            .vertical-line {
                width: 2px;
                height: 150px;
                border-left: 1px solid black;
            }

            .video_box {
                position: relative;

                .video_cont {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    // border-radius: 10px;

                    button {
                        margin-top: -40px;
                        bottom: 0;
                        font-size: 24px;
                    }

                }

                .icons {
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    font-size: 40px;
                    z-index: 2;
                    transform: translate(-50%, -50%);
                    column-gap: 10px;

                    .play_icon,
                    .download_icon {

                        span {
                            font-size: 16px;
                        }
                    }

                    .play_icon {
                        margin-right: 30px;
                    }
                }

            }

        }

        .error {
            color: red;
        }

    }

    .no_video_view_cont {
        h1 {
            font-size: 36px;
        }

        p {
            font-size: 18px;
        }

    }

    .videoContainer {
        background-color: black;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

@media screen and (max-width: 768px) {



    .videos_section {
        padding-top: 0rem;

        .banner_info {

            p {
                font-size: 12px;
            }
        }




        .wrp {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            justify-items: "space-between";
            gap: 2rem;

        }


        .video_wrapper {
            display: flex;
            flex-direction: column;
            margin: auto;
            width: 100%;

            .date_cont {
                display: flex;
                align-items: center;
                align-items: center;
                margin: 1rem 0;
                position: relative;



                p {
                    font-size: 12px;
                    margin: 0;
                    opacity: 0.9;
                }
            }

            .vertical-line {
                width: 2px;
                height: 150px;
                border-left: 1px solid black;
            }

            .video_box {
                position: relative;

                .video_cont {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    // border-radius: 10px;

                    button {
                        margin-top: -40px;
                        bottom: 0;
                        font-size: 18px;
                    }

                }

                .icons {
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    font-size: 40px;
                    z-index: 2;
                    transform: translate(-50%, -50%);
                    column-gap: 10px;

                    .play_icon,
                    .download_icon {

                        span {
                            font-size: 16px;
                        }
                    }

                    .play_icon {
                        margin-right: 30px;
                    }
                }

            }
        }

        .error {
            color: red;
        }

    }

    .no_video_view_cont {
        max-width: 90%;
        margin: auto;

        h1 {
            font-size: 36px;
        }

        p {
            font-size: 18px;
        }

    }

    .videoContainer {
        background-color: black;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

@media screen and (max-width: 576px) {

    .filters {
        display: flex;
        align-items: flex-start;
        flex-direction: column-reverse;
        justify-content: space-between;
        gap: 16px;
        margin:1rem 0;
    
        h1 {
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 0rem;
    
        }
        .counts {
            display: flex;
            align-items: center;
            background-color: #0b8043;
            color: #fff;
            border-radius: 20px;
            padding: 0 12px;
            font-size: 12px;
            font-weight: 500;
            height: 40px;
    
            span {
                background: #fff;
                color: #000;
                font-size: 10px;
                font-weight: bold;
                border-radius: 50px;
                padding: 6px 10px;
                margin-left: 8px;
            }
        }
    }
    .videos_section {
        padding-top: 0rem;

        .wrp {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            justify-items: "space-between";
            gap: 1rem;
            position: relative;

        }



        .video_wrapper {
            display: flex;
            flex-direction: column;
            margin: auto;
            width: 100%;

            .date_cont {
                display: flex;
                align-items: center;
                align-items: center;
                margin: 0.3rem 0rem;




                p {
                    font-size: 12px;
                    margin: 0;
                    opacity: 0.9;
                }
            }

            .vertical-line {
                width: 2px;
                height: 150px;
                border-left: 1px solid black;
            }

            .video_box {
                position: relative;

                .video_cont {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    // border-radius: 10px;

                    button {
                        margin-top: -40px;
                        bottom: 0;
                        font-size: 14px;
                    }

                }

                .icons {
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    font-size: 40px;
                    z-index: 2;
                    transform: translate(-50%, -50%);
                    column-gap: 10px;

                    .play_icon,
                    .download_icon {

                        span {
                            font-size: 16px;
                        }
                    }

                    .play_icon {
                        margin-right: 30px;
                    }
                }

            }
        }

        .error {
            color: red;
        }

    }

    .no_video_view_cont {
        max-width: 90%;
        margin: auto;
        padding-block: 1rem;
        height: 40vh;

        h1 {
            font-size: 28px;
            text-align: center;

        }

        p {
            font-size: 14px;
            text-align: center;
        }

    }

    .videoContainer {
        background-color: black;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

.noVideosFoundForTag {
    color: red;
    font-size: 18px;
    text-align: center;
    align-items: center;
    margin-top: 10%;
  }